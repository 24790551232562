const stringToSlug = (str) => {
  return str
    .toString()
    .normalize("NFKD")
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-");
};

const maskCpf = (v) => {
  v = v.replace(/\D/g, "");
  v = v.replace(/(\d{3})(\d)/, "$1.$2");
  v = v.replace(/(\d{3})(\d)/, "$1.$2"); //de novo (para o segundo bloco de números)
  v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  return v;
};

const onlyNumbers = (v) => {
  return v.replace(/\D/g, "");
};

const maskCnpj = (v) => {
  const cnpjLimpo = v.replace(/\D/g, "");

  // Adicionar a formatação desejada
  return cnpjLimpo.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    "$1.$2.$3/$4-$5"
  );
};

const getIconPaymentMethod = (code) => {
  switch (code) {
    case "card_credit":
      return "bi-credit-card-2-back";
    case "card_debit":
      return "bi-credit-card-2-front";
    case "billet":
      return "bi-upc-scan";
    case "letter":
      return "bi-file-earmark-medical";
    case "pix":
      return "bi-qr-code-scan";
    case "migracao":
      return "bi-arrow-down-up";
    case "cortesia":
      return "bi-gift";
    case "unit":
      return "bi-office";
    case "money":
      return "bi-cash-stack";
    case "check":
      return "bi-card-text";
    default:
      return "";
  }
}

const maskDocument = (v) => {
  const doc = v.replace(/\D/g, "");

  if (doc.length === 11) {
    return doc.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
  } else if (doc.length === 14) {
    return doc.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
  } else {
    return v;
  }
};

const convertWorkloadToMinutes = (workload) => {
	// Se workload for vazio, nulo ou undefined, retornar 0
	if (!workload) return 0;

	// Verificar se é apenas um número (ex.: "700")
	if (!workload.includes(':')) {
		const hours = Number(workload);
		return isNaN(hours) ? 0 : hours * 60;
	}

	// Tratar o formato "HH:MM" (ex.: "700:00")
	const [hours, minutes] = workload.split(':').map(Number);

	// Verificar se os valores são válidos, senão retornar 0
	if (isNaN(hours) || isNaN(minutes)) return 0;

	return (hours * 60) + minutes;
};

const calculateCreditTotal = (hours, price) => {
  const parsedHours = parseFloat(hours);
  const parsedPrice = parseFloat(price);
  if (isNaN(parsedHours) || isNaN(parsedPrice)) return 0;
  return parsedHours * parsedPrice;
}

const calculateCourseHourPrice = () => {
  return this.creditHourPrice * (this.creditHours / this.courseHours);
}

const converterHourToPrice = (time = 0, priceHour = 0) => {
  // Calcular o valor da hora
  const totalMinutes = convertWorkloadToMinutes(time); // 700 * 60 = 42.000 minutos
  const totalHours = totalMinutes / 60; // 42.000 / 60 = 700 horas
  const valorPorHora = priceHour / totalHours; // 3.000,00 / 700
  return valorPorHora
}

const converterMinutesInHours = (min = 0) => {
	// Se o valor for negativo ou 0, retornar "00:00"
	if (min <= 0) return '00:00';

	// Calcular horas e minutos
	const hours = Math.floor(min / 60);
	const minutes = min % 60;

	// Verificar se o resultado é válido (embora com min > 0, hours não será NaN)
	if (isNaN(hours) || isNaN(minutes)) return '00:00';

	// Retornar no formato HH:MM com preenchimento de zeros à esquerda
	return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

module.exports = {
  convertWorkloadToMinutes,
  converterMinutesInHours,
  converterHourToPrice,
  stringToSlug,
  maskCpf,
  onlyNumbers,
  maskCnpj,
  calculateCreditTotal,
  calculateCourseHourPrice,
  maskDocument,
  getIconPaymentMethod,
};
